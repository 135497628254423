<template>
    <div class="menu">
        <div class="menuTree  box-shadow">
            <el-tree :data="treeselectOptions" default-expand-all :expand-on-click-node="false" @node-click="nodeClick">
                <div class="custom-tree-node" :class="{'active':activeParentId==data.id}" slot-scope="{ node, data }">{{  data.name  }}</div>
            </el-tree>
        </div>
        <div class="mainList">
            <div class="mainList_content  box-shadow">
                <div class="mainList_content_operation">


                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" v-if="permission(['MENU_ADD'])" @click="add()">新增</el-button>
                        <el-button size="small" icon="el-icon-sort" type="primary" @click="saveSort" :loading="saveSortLoading">保存排序</el-button>
                    </div>
                    <div class="mainList_content_operation-c">

                    </div>
                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>
                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable"
                              row-key="id"
                              border
                              :data="tableData"
                              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                              tooltip-effect="dark"
                              height="string"
                              :default-expand-all="false"
                              :stripe="elTable.stripe"
                              :border="elTable.border"
                              v-loading="loading"
                              header-cell-class-name="meterHeader"
                        >
                        <el-table-column v-for="(n,i) in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" fixed="right" align="center" width="150" v-if="permission(['MENU_ADD','MENU_DEL','MENU_EDIT'])">
                            <template slot-scope="scope">
                                <el-button type="text" @click="add(scope.row.id)" v-if="permission(['MENU_ADD'])">新增</el-button>
                                <el-button class="edit" type="text" v-if="permission(['MENU_EDIT'])" @click="edit(scope.row)">编辑</el-button>
                                <el-button class="del" type="text" v-if="permission(['MENU_DEL'])"  @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

            </div>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options" :listData="listData"></Edit>
    </div>
</template>
<script>
import {initTreeData} from '@/util/common'

import mixinsList from '@/mixins/list'
import Sortable from "sortablejs";
export default {
    components: {
        Edit: () => import("./Edit.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                del:this.$api['admin/menu'].del,
            },
            options:{
                parentId:'0',
                typeId:0,
                isShow:1
            },
            listData: [],
            treeselectOptions: [],
            activeParentId:'0',
            saveSortLoading:false,
            tableColumnData:[
                {prop:'name',label:'名称',isShow:true,sortable:false,fixed:false,},
                {prop:'href',label:'链接地址',isShow:true,sortable:false,fixed:false,},
                {prop:'component',label:'组件路劲',isShow:true,sortable:false,fixed:false,},
                {prop:'typeId',label:'权限类型',isShow:true,sortable:false,fixed:false,filter:function (v) {
                    return v == 1 ? "按钮" : "菜单";
                }},
                {prop:'code',label:'权限标识',isShow:true,sortable:false,fixed:false,},
            ]
        };
    },
    watch: {
        activeParentId(v){
            var tableData = [];
            this.listData.some(r=>{
                if(r.parentId==v){
                    tableData.push(r)
                }
            })
            this.tableData = tableData;
        }
    },
    created() {
        this.getData();
    },
    mounted(){
        this.elFormTable = this.$refs.multipleTable.$el.querySelector('.el-table__body-wrapper tbody');
        new Sortable(this.elFormTable, {
            animation: 150,
            ghostClass: '.el-table__row',
            onMove:r=>{
                return true;
            },
            onEnd:r=> {
                var currRow = this.tableData.splice(r.oldIndex, 1)[0];
                this.tableData.splice(r.newIndex, 0, currRow);
            }
        });
    },
    methods: {
        saveSort(){
            var dragSortVos = [];

            for(let i=0;i<this.tableData.length;i++){
                let r = this.tableData[i]
                dragSortVos.push({
                    menuId:r.id,
                    sort:i,
                })
            };

            this.saveSortLoading = true;

            this.$api['admin/menu'].menuDragSort({dragSortVos}).then( res => {
                this.saveSortLoading = false;
                this.$message.success("操作成功！");
            }).catch(err=>{
                this.saveSortLoading = false;
                console.log(err);
            })
        },
        nodeClick(r){
            this.activeParentId = r.id;
        },
        add(parentId){
            this.options = {
                parentId:parentId||this.activeParentId,
                typeId:0,
            };
            this.showEditDialog = true
        },
        getData() {
            this.loading = true;
            this.$api['admin/menu'].findList().then(res => {
                this.loading = false;
                this.listData = res.data;

                var tableData = []
                var arr = [];
                res.data.some(r=>{
                    if(r.parentId==this.activeParentId){
                        tableData.push(r)
                    }
                    if(r.typeId!=1){
                        arr.push(r.deepClone())
                    }
                })

                this.tableData = tableData;
                this.treeselectOptions = [
                    {
                        id:'0',
                        name:'所有菜单',
                        children:initTreeData(arr)
                    }
                ];;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.menu {
    padding-left:320px;
    .menuTree {
        position:absolute;
        top:20px;
        bottom:20px;
        left:20px;
        width:300px;
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        overflow:auto;
        font-size:14px;
        /deep/.el-tree {
            .el-tree-node__content {
                background:none;
                &:hover {color:$themeColor;}
            }
            .custom-tree-node {
                &.active {
                    color:$themeColor;
                }
            }
        }
    }
}
</style>
